import React from 'react'
import './index.scss'
import StackedBarChart from './StackedBarChart'

export default function GroupResultsChart (props) {
  const organization = props.organization || {}
  const activeGroup = props.activeGroup || {}
  const groups = props.groups || []
  const remoteGroups = props.remoteGroups // the groups from a remote org source
  const groupTypeToShow = props.groupType
  const groupResultsTitle = props.title
  const modulePrimaryTextColor = props.modulePrimaryTextColor
  const moduleSecondaryTextColor = props.moduleSecondaryTextColor
  const maxGroupsToShow = props.maxGroupsToShow
  const showSubdivisionView = props.showSubdivisionView

  // Apply filters for groups to show
  let filteredGroups = groups.filter(group => group.enable_dashboard === true) // show enabled only
  if (groupTypeToShow) filteredGroups = filteredGroups.filter(group => group.group_type === groupTypeToShow) // filter out specified groups

  const activeGroupForChart = (remoteGroups)
    ? remoteGroups.find(group => group.url_alias === organization.url_alias)
    : activeGroup

  return (
    <div className='group-results-chart'>
      <p className='module-title' style={{ color: modulePrimaryTextColor }}>
        {groupResultsTitle}
      </p>
      {(() => {
        if (groups.length > 0) {
          return (
            <StackedBarChart
              activeGroup={activeGroupForChart}
              showSubdivisionView={showSubdivisionView}
              organization={organization}
              groups={filteredGroups}
              maxGroupsToShow={maxGroupsToShow}
              modulePrimaryTextColor={modulePrimaryTextColor}
              moduleSecondaryTextColor={moduleSecondaryTextColor}
            />
          )
        }
      })()}
    </div>
  )
}
