// -----------------------------------------------------------------------------
// Moonshot Data Client
// -----------------------------------------------------------------------------
import objectUtils from '../utils/object-utils'
import HttpService from './http'
import toModel from './normalizer'

const debug = false
const logNamespace = '[DATA-CLIENT]'
const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL
const authToken = process.env.API_TOKEN

// Proxy through Netlify functions
const netlify = new HttpService({
  debug: false,
  baseUrl: '/.netlify/functions',
  authToken
})

// Available methods
const dataClient = {
  generateLogoImagePath,
  generateBgImageInfo,
  generateGroupResultsTitle,
  generateBrandingColors,
  generateMaxGroupsToShow,
  generateMetricsToShow,
  generateTotalResultsTitle,
  loadOrganization,
  loadGroups,
  fetchOrgAssociations,
  loadCustomOrganization,
  fetchCustomOrgAssociations,
  fetchWeeklyStats,
  fetchMonthlyStats,
  fetchHistoricalTotals,
  fetchCurrentTotals,
  fetchCityLevelStats,
  fetchCommunityLevelStats,
  fetchContainerTypeDef,
  fetchData,
  submitSubscriberWeight,
  lookupCustomerId,
  lookupSubscriberInfo,
  fetchSubscriberTotalWeight
}

// -------------------------------------------------------------------- Branding

function generateLogoImagePath (orgAlias = 'downtown', branding = {}) {
  if (debug) console.log(`${logNamespace} Generating logo image path for ${orgAlias}:`, branding)
  const logoImageName = branding.logo_image_name || 'logo.png'
  const logoImagePath = `${assetsBaseUrl}/${orgAlias}/${logoImageName}`

  return logoImagePath
}

// Returns: { imagePath, imageFromTop }
function generateBgImageInfo (orgAlias, branding = {}, activeGroup = {}) {
  if (debug) console.log(`${logNamespace} Generating bg image info for ${orgAlias}:`, branding, activeGroup)

  // Load organization-level settings (by default)
  let bgImageName = branding.bg_image_name || 'bg.png'
  let bgImageFromTop = branding.bg_image_from_top || 0

  // Load group page override, if configured
  if (activeGroup.settings && activeGroup.settings.bg_image_name) bgImageName = activeGroup.settings.bg_image_name
  if (activeGroup.settings && activeGroup.settings.bg_image_from_top) bgImageFromTop = activeGroup.settings.bg_image_from_top

  // Construct image path
  const bgImagePath = `${assetsBaseUrl}/${orgAlias}/${bgImageName}`

  return { bgImagePath, bgImageFromTop }
}

function generateGroupResultsTitle (branding = {}) {
  if (debug) console.log(`${logNamespace} Generating group results title from org settings:`, branding)

  let title = 'Location Results' // default
  const overrideTitle = branding.group_results_title
  const groupTypeToShow = branding.group_type_to_show

  // Use explicit override title, if configured
  if (overrideTitle && overrideTitle.length > 0) {
    title = overrideTitle

  // Else, use group type filter, if configured
  } else if (groupTypeToShow && groupTypeToShow.length > 0) {
    title = `${groupTypeToShow} Results`
  }

  return title
}

function generateBrandingColors (branding = {}) {
  return {
    modulePrimaryTextColor: branding.module_primary_text_color || '#0a509e',
    moduleSecondaryTextColor: branding.module_secondary_text_color || '#98b4ca',
    pageLabelTextColor: branding.page_label_text_color || '#FFFFFF'
  }
}

function generateMaxGroupsToShow (branding = {}) {
  if (debug) console.log(`${logNamespace} Get max group to show value`, branding)
  return branding.max_groups_to_show || 10
}

function generateMetricsToShow (branding = {}) {
  if (debug) console.log(`${logNamespace} Generating metrics to show from org settings:`, branding)
  const orgOverride = branding.group_summary_metrics

  const hasOverrides = orgOverride && Array.isArray(orgOverride) && orgOverride.length > 0
  const metricAliases = (hasOverrides)
    ? orgOverride
    : ['trees-planted', 'miles-undriven', 'co2-saved']

  return metricAliases
}

function generateTotalResultsTitle (branding = {}) {
  if (debug) console.log(`${logNamespace} Generating total results title from org settings:`, branding)
  return branding.total_results_title || 'Total Results'
}

// ----------------------------------------------------------- Organization Data

// options = {
//   params: <params_object>,
//   force: true | false, --> default: false
// }
async function loadOrganization (options = {}) {
  const force = options.force
  const resource = 'organization'

  if (debug) console.log(`${logNamespace} Loading ${resource}...`, options)

  const existing = false // TODO - Check store if loaded Organization exists !!!

  const params = (options.params) ? Object.assign({}, options.params) : {}
  const opts = { resource, params }

  if (!existing || force) {
    const item = await fetchData(opts)
    return item
  }

  return false
}

// ------------------------------------------------------------------ Group Data

// options = {
//   params: <params_object>,
//   force: true | false, --> default: false
// }
async function loadGroups (options = {}) {
  const force = options.force
  const resource = 'groups'

  if (debug) console.log(`${logNamespace} Loading ${resource}...`, options)

  const existing = false // TODO - Check store if loaded Groups exists !!!

  const params = (options.params) ? Object.assign({}, options.params) : {}
  const opts = { resource, params }

  if (!existing || force) {
    const collection = await fetchData(opts)
    return collection
  }

  return false
}

// ------------------------------------------------------------ Org Associations

// Fetch org associations
async function fetchOrgAssociations (orgId) {
  const uri = `/org/${orgId}/associations`

  if (debug) console.log(`${logNamespace} Fetching org associations via ${uri}`)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    if (debug) console.log(`${logNamespace} org associations obtained:`, payload)

    const results = toModel(payload)
    return results
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} Error fetching org associations =>`, error)
    }
    throw error
  }
}

// ------------------------------------------------------------- Custom Org Data

// options = {
//   params: <params_object>,
//   force: true | false, --> default: false
// }
async function loadCustomOrganization (options = {}) {
  const force = options.force
  const resource = 'custom-org'

  if (debug) console.log(`${logNamespace} Loading ${resource}...`, options)

  const existing = false // TODO - Check store if loaded CustomOrg exists !!!

  const params = (options.params) ? Object.assign({}, options.params) : {}
  const opts = { resource, params }

  if (!existing || force) {
    const item = await fetchData(opts)
    return item
  }

  return false
}

// Fetch custom org associations
async function fetchCustomOrgAssociations (orgId) {
  const uri = `/custom-org/${orgId}/associations`

  if (debug) console.log(`${logNamespace} Fetching custom org associations via ${uri}`)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    if (debug) console.log(`${logNamespace} custom org associations obtained:`, payload)

    const results = toModel(payload)
    return results
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} Error fetching custom org associations =>`, error)
    }
    throw error
  }
}

// ------------------------------------------------ Stats Data (weekly, monthly)

// Fetch weekly stats by group ID
async function fetchWeeklyStats (groupId) {
  const uri = `/group/${groupId}/total/for-week`

  if (debug) console.log(`${logNamespace} Fetching weekly stats via ${uri}`)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    if (debug) console.log(`${logNamespace} weekly stats obtained:`, payload)
    const totalInLbs = objectUtils.get(payload, 'data.attributes.totals.week', 0)

    return {
      stats_weekly: { total_in_lbs: totalInLbs }
    }
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} fetchWeeklyStats error =>`)
      console.log(error)
    }
    throw error
  }
}

// Fetch monthly stats by group ID
async function fetchMonthlyStats (groupId) {
  const uri = `/group/${groupId}/total/for-month`

  if (debug) console.log(`${logNamespace} Fetching monthly stats via ${uri}`)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    if (debug) console.log(`${logNamespace} monthly stats obtained:`, payload)
    const totalInLbs = objectUtils.get(payload, 'data.attributes.totals.month', 0)

    return {
      stats_monthly: { total_in_lbs: totalInLbs }
    }
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} fetchMonthlyStats error =>`)
      console.log(error)
    }
    throw error
  }
}

// -------------------------------------------- Stats Data (historical, current)

// Fetch historical totals by org URL alias. Filter by array of group aliases.
// 404 is handled.
//
// options = {
//   filter       - Array of specific groups to include
//   subdivisions - Boolean to include results with subdivision groupings
// }
async function fetchHistoricalTotals (orgAlias, options = {}) {
  const filter = (options.filter) ? options.filter.join(',') : null
  const subdivisions = !!options.subdivisions

  const uri = `/org/${orgAlias}/historical-totals`
  const params = { subdivisions }
  if (filter) params.filter = filter

  if (debug) console.log(`${logNamespace} Fetching historcal stats via ${uri}`)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`, {
      ...params,
      validateStatus: status => status === 200 || status === 404
    })
    if (debug) console.log(`${logNamespace} historical totals obtained:`, payload)

    return payload.data || {}
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} fetchHistoricalTotals error =>`)
      console.log(error)
    }
    throw error
  }
}

// Fetch current totals by org URL alias. Filter by array of group aliases.
async function fetchCurrentTotals (orgAlias, filter = null) {
  if (filter) filter = filter.join(',')

  const uri = (filter)
    ? `/org/${orgAlias}/current-totals?filter=${filter}`
    : `/org/${orgAlias}/current-totals`

  if (debug) console.log(`${logNamespace} Fetching current stats via ${uri}`)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    if (debug) console.log(`${logNamespace} current totals obtained:`, payload)

    return payload.data || {}
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} fetchCurrentTotals error =>`)
      console.log(error)
    }
    throw error
  }
}

// ----------------------------------------------------- Stats Data (city-level)

// Fetch city-level stats by city alias
async function fetchCityLevelStats (cityAlias) {
  const uri = '/cache/stats'

  if (debug) console.log(`${logNamespace} Fetching city-level stats for ${cityAlias} via ${uri}`)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    const data = payload.data || {}
    if (debug) console.log(`${logNamespace} dashboard stats obtained:`, data)

    // Extract city-level info per residential and commercial
    const comStats = objectUtils.get(data, `commercial.city_totals[${cityAlias}]`, {})
    const comTotal = comStats.total_weight || 0
    const resStats = objectUtils.get(data, `residential.city_totals[${cityAlias}]`, {})
    const resTotal = resStats.total_weight || 0
    const totalWeight = Number(comTotal) + Number(resTotal)

    return {
      stats_total: {
        total_in_lbs: totalWeight
      },
      commercial: comStats,
      residential: resStats
    }
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} fetchCityLevelStats error =>`)
      console.log(error)
    }
    throw error
  }
}

// ------------------------------------------------ Stats Data (community-level)

// Fetch community-level stats by community alias
async function fetchCommunityLevelStats (communityAlias) {
  const uri = `/org/${communityAlias}/current-totals`

  if (debug) console.log(`${logNamespace} Fetching community-level stats for ${communityAlias} via ${uri}`)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    const data = payload.data || {}
    if (debug) console.log(`${logNamespace} ${communityAlias} stats obtained:`, data)

    // Extract community-level info
    const { total_weight: communityTotalWeight, ...attrs } = data
    const communityTotal = communityTotalWeight || 0

    return {
      ...attrs,
      stats_total: {
        total_in_lbs: Number(communityTotal)
      }
    }
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} fetchCommunityLevelStats error =>`)
      console.log(error)
    }
    throw error
  }
}

// ------------------------------------------------ Container Type Def

// Fetch container type definition by code
async function fetchContainerTypeDef (code) {
  const uri = `/container-type/${code}`

  if (debug) console.log(`${logNamespace} Fetching container type via ${uri}`)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    if (debug) console.log(`${logNamespace} container type obtained:`, payload)

    const containerType = toModel(payload)
    return containerType
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} Error fetching container type =>`, error)
    }
    throw error
  }
}

// ------------------------------------------------------------ Fetch Data Logic

// options = {
//   resource,
//   params: <params_object>,
// }
async function fetchData (options = {}) {
  const resource = options.resource
  const allParams = options.params || {}
  const { orgAlias, ...params } = allParams // pull out orgAlias from params to use in uri path

  let uri = ''
  switch (resource) {
    case 'organization': uri = `/org/${orgAlias}`; break
    case 'custom-org': uri = `/custom-org/${orgAlias}`; break
    case 'groups': uri = `/org/${orgAlias}/groups`; break
    case 'dropoff-locations': uri = '/dropoff-locations'; break
  }

  if (debug) console.log(`${logNamespace} Fetching ${resource} data via ${uri}`, options)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`, { params })
    const model = toModel(payload)
    if (debug) console.log(`${logNamespace} ${resource} model:`, model)
    return model
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} fetchData error =>`)
      console.log(error)
    }
    throw error
  }
}

// --------------------------------------------------------- Submit Weight Logic

// Submits a weight collection for a Subscriber (i.e. a subscriber group).
//
// data = {
//   group_id: <String>,
//   weight_in_lbs: <Number>,
//   container_type: <String>,   --> 'bin' | 'drum'
//   dropoff_location: <String>, --> the location code
//   collection_date: <String>   --> (optional) if not provided, set to now
//   comments: <String>          --> (optional)
// }
//
// RETURNS model: WasteCollectionSubmission
async function submitSubscriberWeight (data = {}) {
  // Validate input
  const requiredFields = ['group_id', 'weight_in_lbs', 'container_type', 'dropoff_location']
  const missingFields = parseMissingFields(data, requiredFields)
  if (missingFields.length > 0) throw generateMissingParametersError(missingFields)

  // Parse input
  const groupId = data.group_id
  const weight = (data.weight_in_lbs) ? Number(data.weight_in_lbs) : 0
  const containerType = data.container_type
  const dropoffLocationCode = data.dropoff_location
  const collectionDate = data.collection_date || new Date().toISOString()
  const comments = data.comments

  const uri = `/group/${groupId}/submit-weight`

  if (debug) console.log(`${logNamespace} Submitting subscriber weight via ${uri}`, data)

  const bodyData = {
    group_id: groupId,
    weight_in_lbs: weight,
    container_type: containerType,
    collection_code: 'SS',
    dropoff_location: dropoffLocationCode,
    collection_date: collectionDate,
    comments: comments
  }
  const payload = await netlify.post(`/dashboardApi?uri=${uri}`, { bodyData })
  const model = toModel(payload)
  if (debug) console.log(`${logNamespace} submitSubscriberWeight returned model:`, model)

  return model
}

// Returns the "Customer ID" for the provided Group ID -or null if not found
async function lookupCustomerId (groupId) {
  if (!groupId) return null
  const uri = `/group/${groupId}/customer-id`

  if (debug) console.log(`${logNamespace} Looking up Customer ID via ${uri}`)

  let customerId = null
  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    if (debug) console.log(`${logNamespace} lookupCustomerId returned:`, payload.data)
    customerId = (payload.data) ? payload.data.customer_id : null
  } catch (error) {
    console.error(`${logNamespace} Error => lookupCustomerId for groupId: ${groupId}`, error.message)
  }

  return customerId
}

// params = {
//   email | phone
// }
async function lookupSubscriberInfo (params = {}) {
  const uri = '/subscriber/lookup'

  if (debug) console.log(`${logNamespace} Looking up Subscriber info via ${uri}`, params)

  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`, { ...params })
    const model = toModel(payload)
    if (debug) console.log(`${logNamespace} SubscriberLookupInfo model:`, model)

    return model
  } catch (error) {
    if (debug) {
      console.log(`${logNamespace} lookupSubscriberInfo error =>`)
      console.log(error)
    }
    throw error
  }
}

// Returns the total weight for the provided Customer ID -or null if not found
async function fetchSubscriberTotalWeight (customerId) {
  if (!customerId) return null
  const uri = `/subscriber/${customerId}/total`

  if (debug) console.log(`${logNamespace} Fetching Subscriber total info via ${uri}`)

  let subscriberTotal = null
  try {
    const payload = await netlify.get(`/dashboardApi?uri=${uri}`)
    const model = toModel(payload)
    if (debug) console.log(`${logNamespace} SubscriberTotalInfo model:`, model)
    subscriberTotal = model.running_total_in_lbs
  } catch (error) {
    console.error(`${logNamespace} Error => fetchSubscriberTotalWeight for customerId: ${customerId}`, error.message)
  }

  return subscriberTotal
}

// --------------------------------------------------------------------- Helpers

function generateMissingParametersError (missing = []) {
  const missingAsString = (missing && Array.isArray(missing) && missing.length > 0)
    ? ` : "${missing.join('", "')}"`
    : ''
  const message = `The request is invalid due to missing parameters${missingAsString}`
  const error = new Error()
  error.status = 400
  error.message = message
  return error
}

function parseMissingFields (data = {}, requiredFields = []) {
  const missingFields = []
  requiredFields.forEach((field) => {
    if (!data[field]) missingFields.push(field)
  })
  return missingFields
}

export default dataClient
